<template>
	<div>
		<el-card>
			<search-form ref="searchForm" :topVisible="true" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
				<template #topBtn>
					<el-button class="marginRight20" type="primary" size="small" @click="handleClick('create')">{{ $t('text.add') }}</el-button>
				</template>
			</search-form>
			<create-table :operationColWidth="150" @handleNewTab="handleNewTab($event, 'bannerDetail')" :autoWidth="true" :operateVisible="operateVisible" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" @selectionChange="selectionChange" :topButtonVisible="true" :tableData="tableData" :tableDataItem="tableDataItem" @operate="handleClick" :pageVisible="true" :pageData="pageData" :checkBoxVisible="dropDataItem.length > 0" @handleSort="handleSort" @onSwitchChange="handleSwitchChange">
				<template #topButton>
					<span v-if="dropDataItem.length > 0" class="marginRight20 spanminWith">{{`${$t('text.selected')}：${selectionData.length}`}}</span>
					<allButton :buttonDisabled="selectionData.length < 1" :buttonDataItem="dropDataItem" class="marginRight20" @chooseDropItem="chooseDropItem"></allButton>
				</template>
				<template #rightBtn="scope">
					<el-button class="margintop10" :class="item.action === 'view' ? 'operate_view' : ''" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
					<el-button class="margintop10" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
				</template>
			</create-table>
		</el-card>
		<router-view />
		<dialogEffect v-if="dialogVisible" :opereteDate="false" :todayVisible="true" :dialogVisible.sync="dialogVisible" :data="dialogData" @doNextConfirm="doNextConfirm"></dialogEffect>
	</div>
</template>

<script>
import {
	getHotelsSetup,
	getHotelsList,
	deleteHotelsDetails,
	updateHotelsOnline,
	updateHotelsOffline,
} from '@/services/basic-msg'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import loading from '@/utils/loading.json'
export default {
	name: 'managerUserOrders',
	mixins: [mixin],
	components: {
		searchForm,
		createTable,
		allButton,
		dialogEffect,
	},
	data() {
		return {
			tableDataItem: jsonData.tableDataItem,
		}
	},
	watch: {
		$route(to, from) {
			// console.log(from)
			// if (from.name === 'managerUserOrderView') {
			//   this.$nextTick(() => {
			//     this.search()
			//     this.$refs.createTable.$refs.table.doLayout()
			//   })
			// }
		},
	},
	created() {
		this.queryData(getHotelsSetup)
	},
	activated() {
		if (this.isActive) this.queryData(getHotelsSetup)
		this.isActive = true
	},
	methods: {
		// 点击操作按钮
		async handleClick(type, data) {
			if (type === 'create') {
				this.$router.push({
					name: 'hotelAdd',
				})
			}
			if (type?.action === 'view') {
				this.$router.push({
					name: 'hotelDetail',
					params: {
						id: data.id.value,
					},
				})
			}
			if (type?.action === 'create') {
				this.$router.push({
					name: 'hotelAdd',
				})
			}
			if (type?.action === 'edit') {
				this.$router.push({
					name: 'hotelEdit',
					params: {
						id: data.id.value,
					},
				})
			}
			if (type?.action === 'destroy') {
				this.deleteClick(data, deleteHotelsDetails)
			}
		},
		handleSwitchChange(data) {
			const { jsonItemData, rowData } = data
			this.$store.state.app.loading = this.$loading(loading)
			if (rowData[jsonItemData.prop].value) {
				updateHotelsOnline(rowData.id.value)
					.then((res) => {
						this.$message({
							type: 'success',
							message: this.$t('message.operateSuccess'),
						})
					})
					.finally(() => {
						this.$store.state.app.loading.close()
					})
			} else {
				updateHotelsOffline(rowData.id.value)
					.then((res) => {
						this.$message({
							type: 'success',
							message: this.$t('message.operateSuccess'),
						})
					})
					.finally(() => {
						this.$store.state.app.loading.close()
					})
			}
		},
		// 查询
		search(data) {
			if (data) this.searchData = data
			const { page, per_page } = this.pageData
			Object.assign(this.searchData, { page, per_page })
			this.loading = true
			getHotelsList(this.searchData)
				.then((res) => {
					if (res.data) {
						this.tableData = res.data.objects
						this.tabData = res.data.scopes
						Object.assign(this.pageData, res.data.pagination)
					}
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
}
</script>

<style>
</style>
