<template>
  <div class="allButton">
    <!-- <el-dropdown @command="chooseDropItem">
      <el-button type="primary">
        {{$t('button.batchProcessing')}}<i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item :disabled="dropDisabled" v-for="item in dropDataItem" :key="item.name" :command="item.name">{{item.name}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
     <el-button type="primary" :disabled="buttonDisabled" v-for="item in buttonDataItem" :key="item.name" @click="chooseDropItem(item)">
        {{item.name}}
      </el-button>
  </div>
</template>

<script>
export default {
  name: 'allButton',
  props: {
    buttonDataItem: {
      type: Array,
      default () {
        return []
      }
    },
    buttonDisabled: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    chooseDropItem (val) {
      this.$emit('chooseDropItem', val)
    }
  }
}
</script>

<style lang="scss" scope>
.allButton {
  display: flex;
  flex: 1;
  overflow: auto;
  .is-disabled {
    color: #40586f;
    background-color: #eff4f8;
    border: 1px solid #eff4f8;
  }
}
</style>
