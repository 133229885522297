/**
 * @name 餐厅管理
 */
import request from '@/utils/request'

export const getRestaurantsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/restaurants/setup'
  })
}

export const getRestaurantsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/restaurants',
    params
  })
}

export const getRestaurantsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/restaurants/${id}/preview`
  })
}

export const getRestaurantsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/restaurants/${id}`
  })
}

export const getRestaurantsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/restaurants/collections'
  })
}

export const deleteRestaurantsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/restaurants/${id}`
  })
}

export const postRestaurantsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/restaurants',
    data
  })
}

export const putRestaurantsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/restaurants/${id}`,
    data
  })
}

export const updateRestaurantsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/restaurants/${id}/online`
  })
}

export const updateRestaurantsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/restaurants/${id}/offline`
  })
}

/**
 * @name 酒店列表
 */

export const getHotelsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/hotels/setup'
  })
}

export const getHotelsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/hotels',
    params
  })
}

export const deleteHotelsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/hotels/${id}`
  })
}

export const postHotelsAdd = (data) => {
  return request({
    method: 'POST',
    url: '/cms/hotels',
    data
  })
}

export const getHotelsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/hotels/collections'
  })
}

export const updateHotelsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotels/${id}/online`
  })
}

export const updateHotelsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/hotels/${id}/offline`
  })
}

export const getHotelsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotels/${id}/preview`
  })
}

export const putHotelsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/hotels/${id}`,
    data
  })
}

export const getHotelsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/hotels/${id}`
  })
}

/**
 * @name 购物管理
 */

export const getShoppingsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shoppings/setup'
  })
}

export const getShoppingsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shoppings',
    params
  })
}

export const getShoppingsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shoppings/${id}/preview`
  })
}

export const getShoppingsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shoppings/${id}`
  })
}

export const getShoppingsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/shoppings/collections'
  })
}

export const deleteShoppingsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shoppings/${id}`
  })
}

export const postShoppingsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/shoppings',
    data
  })
}

export const putShoppingsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/shoppings/${id}`,
    data
  })
}

export const updateShoppingsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shoppings/${id}/online`
  })
}

export const updateShoppingsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shoppings/${id}/offline`
  })
}

/**
 * @name 体验管理
 */

export const getExperiencesSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/experiences/setup'
  })
}

export const getExperiencesList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/experiences',
    params
  })
}

export const getExperiencesEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/experiences/${id}/preview`
  })
}

export const getExperiencesDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/experiences/${id}`
  })
}

export const getExperiencesCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/experiences/collections'
  })
}

export const deleteExperiencesDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/experiences/${id}`
  })
}

export const postExperiencesFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/experiences',
    data
  })
}

export const putExperiencesFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/experiences/${id}`,
    data
  })
}

export const updateExperiencesOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/experiences/${id}/online`
  })
}

export const updateExperiencesOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/experiences/${id}/offline`
  })
}

/**
 * @name 优惠管理
 */

export const getPromotionsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/promotions/setup'
  })
}

export const getPromotionsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/promotions',
    params
  })
}

export const getPromotionsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/promotions/${id}/preview`
  })
}

export const getPromotionsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/promotions/${id}`
  })
}

export const getPromotionsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/promotions/collections'
  })
}

export const deletePromotionsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/promotions/${id}`
  })
}

export const postPromotionsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/promotions',
    data
  })
}

export const putPromotionsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/promotions/${id}`,
    data
  })
}

export const updatePromotionsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/promotions/${id}/online`
  })
}

export const updatePromotionsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/promotions/${id}/offline`
  })
}

export const getPOIList = (id) => {
  return request({
    method: 'GET',
    url: `cms/promotions/poi_list?poi_type=${id}`
  })
}

/**
 * @name 演出管理
 */

export const getShowsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/shows/setup'
  })
}

export const getShowsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/shows',
    params
  })
}

export const getShowsEditDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shows/${id}/preview`
  })
}

export const getShowsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/shows/${id}`
  })
}

export const getShowsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/shows/collections'
  })
}

export const deleteShowsDetails = (id) => {
  return request({
    method: 'DELETE',
    url: `/cms/shows/${id}`
  })
}

export const postShowsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/shows',
    data
  })
}

export const putShowsFormData = (data, id) => {
  return request({
    method: 'PUT',
    url: `cms/shows/${id}`,
    data
  })
}

export const updateShowsOnline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shows/${id}/online`
  })
}

export const updateShowsOffline = (id) => {
  return request({
    method: 'POST',
    url: `cms/shows/${id}/offline`
  })
}

// 景点simple list
export const getAttractionsSimpleList = () => {
  return request({
    method: 'GET',
    url: 'cms/sites/simple_list'
  })
}
